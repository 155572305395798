var generic = generic || {};
var site = site || {};

(function ($) {
  site.emailSignup = {
    templateContainer: $(),

    initEmailSignup: function () {
      var $emailContainerNodes = this.templateContainer;

      if (!$emailContainerNodes.length) {
        return null;
      }
      $emailContainerNodes.each(function () {
        var $emailContainerNode = $(this);
        var $emailForm = $emailContainerNode.is('form') ? $emailContainerNode : $('form', $emailContainerNode);
        var $emailSuccess = $('.js-email-signup__success', $emailContainerNode);
        var $emailSignedUp = $('.js-email-signedup__success', $emailContainerNode);
        var $emailError = $('.js-email-signup__error', $emailContainerNode);
        var $emailInput = $('input[name="PC_EMAIL_ADDRESS"]', $emailContainerNode);
        var $mobileNumberInput = $('input[name="MOBILE_PHONE"]', $emailContainerNode);
        var $termsAndConditions = $('.js-terms-conditions', $emailContainerNode);
        var $emailFormFields = $('.site-email-signup__field', $emailContainerNode);

        // Show terms + conditions for mobile only
        $mobileNumberInput.on('focus', function (e) {
          $termsAndConditions.slideDown(300);
        });

        $emailForm.once('js-email-signup').submit(function (submitEvt) {
          submitEvt.preventDefault();
          $emailSuccess.add($emailError).addClass('hidden');
          $emailSignedUp.add($emailError).addClass('hidden');
          $emailFormFields.removeClass('error');

          // Transform string into array of form elements
          var params = {};

          $.each($emailForm.serializeArray(), function (index, kv) {
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });

          // handle the sms_promotions checkbox - if its checked its being read as 'on' when serialized
          // we need a boolean value
          if ($emailContainerNode.find('input[name="SMS_PROMOTIONS"]').prop('checked')) {
            params.SMS_PROMOTIONS = 1;
          }

          // Send the data via a json rpc call
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (jsonRpcResponse) {
              var response = jsonRpcResponse.getData();
              var alreadySignedUp = response.userinfo.previously_opted_in_email_promotion;
              var html_content = $emailSuccess.html();

              if (alreadySignedUp) {
                html_content = $emailSignedUp.html();
              }

              if ($emailSuccess.hasClass('inline')) {
                $emailSuccess.removeClass('hidden');
              } else {
                // Close active utility items
                Drupal.behaviors.gnav.close();
                $emailInput.val('');
                $mobileNumberInput.val('');
                $termsAndConditions.slideUp(0);
                if ($emailContainerNodes.hasClass('js-promotion-sign-up')) {
                  $('#cboxContent').addClass('popup-content');
                  generic.overlay.launch({
                    close: '<i class="icon icon--close"></i>',
                    cssClass: 'promotion-signup-thanks',
                    height: 'auto',
                    html: html_content
                  });
                } else {
                  generic.overlay.launch({
                    height: 'auto',
                    html: html_content
                  });
                }
              }
            },
            onFailure: function (jsonRpcResponse) {
              // Get errors back and build a set of list items to inject into the HTML.
              var errorObjectsArray = jsonRpcResponse.getMessages();
              var errorHTML = '';

              for (var i = 0; i < errorObjectsArray.length; i++) {
                errorHTML += '<li>' + errorObjectsArray[i].text + '</li>';
                // Use the jsonRpcResponse tags to pair with input nodes.
                // Error tags are returned with details of which form, field are tagged to that particular error message
                for (var j = 2; j < errorObjectsArray[i].tags.length; j++) {
                  var fieldName = errorObjectsArray[i].tags[j].substr(6).toUpperCase();
                  var $node = $('[name=' + fieldName + ']', $emailForm);

                  $node.addClass('error');
                }
              }
              $emailError.html(errorHTML).removeClass('hidden');
            }
          });
        });
      });
    }
  };

  Drupal.behaviors.emailSignupFormV1 = {
    attach: function (context) {
      site.emailSignup.templateContainer = $('.js-email-signup', context);
      site.emailSignup.initEmailSignup();
    }
  };
})(jQuery);
